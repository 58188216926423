import { List, Set } from "immutable";
import React, { Suspense, useState, useCallback, useEffect } from "react";
import { useCookies } from "react-cookie";
import { graphql, useFragment, usePreloadedQuery } from "react-relay";
import { unstable_usePrompt } from "react-router";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import ErrorHandler from "~/components/ErrorHandler";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Add from "@mui/icons-material/Add";

import Paper, { PaperProgress } from "~/components/Paper";
import Button from "~/components/Button";
import EditDateDialog from "~/components/EditDateDialog";
import {
  FORM_FRAGMENT,
  FORM_MUTATION,
  ContractFormSelect,
} from "~/components/FormSelect";
import { boxMargin, FieldBox, GroupBox } from "~/components/Boxes";
import {
  MyCompaniesFieldset,
  CounterpartiesFieldset,
  PartiesFieldset,
} from "~/components/Parties";
import { FormField } from "~/components/FormFields";
import { getValueProp } from "../core/contracts/fieldHelpers";
import DateAndReminder from "~/components/DateAndReminder";
import useDebouncedCommit from "~/utils/UseDebouncedCommit";
import { AUTORENEW_MAP } from "~/utils/terminationDate";
import { TerminationDateAndReminder } from "./TerminationDateAndReminder";
import { stripFragmentProps } from "../utils/stripFragmentProps";
import { track } from "~/analytics/events";
import CONTRACT_FORMS_CONTRACT_QUERY from "~/contracts/queries/ContractFormsContract";

export const FIELD_VALUE_FRAGMENT = graphql`
  fragment ContractForms_fieldValueFragment on FieldValueType {
    __typename
    ... on BuiltinFieldValueType {
      field {
        id
      }
      id
      value
      currencySymbol
    }
    ... on CustomFieldValueType {
      field {
        id
      }
      id
      value
      valueList {
        id
        name
        pairedValue
        author
        itemDate
        sortindex
      }
      valueDate {
        ...EditDateDialog_dateFragment @relay(mask: false)
      }
      currencySymbol
    }
  }
`;

function Field({
  contractId,
  contractUuid,
  dateFormat,
  field,
  onCommit,
  userEmail,
  required,
  disabled,
  annotations,
  onJumpClick,
  ...props
}) {
  const fieldValue =
    useFragment(
      FIELD_VALUE_FRAGMENT,
      props.fieldValue?.__fragments ? props.fieldValue : null,
    ) || props.fieldValue;

  const [cookies, setCookie] = useCookies(["currency_selected"]);
  const choicesById = Object.fromEntries(
    field.choices?.map((c) => [c.id, c]) || [],
  );

  const valueProp = getValueProp(field);

  const [rawAddCommit, isRawAddInFlight] = useAsyncMutation(graphql`
    mutation ContractForms_fieldValueAddMutation(
      $contractUuid: String!
      $fieldId: ID!
      $value: String
      $valueList: [ValueListItemInput]
      $valueDate: Date
      $currencySymbol: String
    ) {
      updateFieldValue(
        input: {
          contractUuid: $contractUuid
          fieldValue: {
            fieldId: $fieldId
            value: $value
            valueList: $valueList
            valueDate: $valueDate
            currencySymbol: $currencySymbol
          }
        }
      ) {
        fieldValue {
          ...ContractForms_fieldValueFragment
        }
        fieldValueSet {
          id
          values
        }
        contract {
          id
          currentFormMissingRequiredFields
          customFieldValues {
            ...ContractForms_fieldValueFragment
          }
        }
      }
    }
  `);
  const [rawUpdateCommit, isRawUpdateInFlight] = useAsyncMutation(graphql`
    mutation ContractForms_fieldValueUpdateMutation(
      $contractUuid: String!
      $fieldId: ID!
      $value: String
      $valueList: [ValueListItemInput]
      $valueDate: Date
      $currencySymbol: String
    ) {
      updateFieldValue(
        input: {
          contractUuid: $contractUuid
          fieldValue: {
            fieldId: $fieldId
            value: $value
            valueList: $valueList
            valueDate: $valueDate
            currencySymbol: $currencySymbol
          }
        }
      ) {
        fieldValue {
          ...ContractForms_fieldValueFragment
        }
        fieldValueSet {
          id
          values
        }
        contract {
          id
          currentFormMissingRequiredFields
        }
      }
    }
  `);

  const commit = useCallback(
    async (newValue, currencySymbol) => {
      if (newValue === "" && field.fieldType == "a") {
        newValue = null;
      }
      const responseValue =
        valueProp == "value"
          ? newValue
          : valueProp == "valueList"
            ? [
                ...newValue.map((x) => ({
                  id: null,
                  author: null,
                  pairedValue: null,
                  itemDate: null,
                  sortindex: null,
                  ...choicesById[x["id"]],
                  ...x,
                })),
              ]
            : valueProp == "valueDate"
              ? {
                  ...fieldValue?.valueDate,
                  reminder: stripFragmentProps(fieldValue?.valueDate?.reminder),
                  ...newValue,
                }
              : null;

      const variables = {
        contractUuid,
        fieldId: field.id,
        currencySymbol,
        [valueProp]: valueProp == "valueDate" ? newValue?.date : newValue,
      };

      try {
        if (fieldValue?.id) {
          const r = await rawUpdateCommit({
            variables,
            optimisticResponse: {
              updateFieldValue: {
                fieldValue: {
                  ...fieldValue,
                  currencySymbol:
                    currencySymbol !== undefined
                      ? currencySymbol
                      : (symbol ?? null),
                  [valueProp]: responseValue,
                },
                fieldValueSet: null,
                contract: {
                  id: contractId,
                  currentFormMissingRequiredFields: true,
                },
              },
            },
          });
          onCommit();
          return r;
        } else {
          const r = await rawAddCommit({ variables });
          onCommit();
          return r;
        }
      } catch (e) {
        onCommit(e.message);
      }
    },
    [contractId, contractUuid, valueProp, field.id, cookies, fieldValue],
  );

  const [value, setValue, debouncedCommit, flushDebouncedCommit] =
    useDebouncedCommit(
      commit,
      isRawAddInFlight || isRawUpdateInFlight,
      fieldValue?.[valueProp],
      [contractId, contractUuid, valueProp, field.id],
    );

  const [symbol, setSymbol] = useState(
    fieldValue?.currencySymbol && (value || fieldValue?.valueList?.length)
      ? fieldValue.currencySymbol
      : cookies.currency_selected,
  );
  useEffect(
    () =>
      setSymbol(
        fieldValue?.currencySymbol && (value || fieldValue?.valueList?.length)
          ? fieldValue.currencySymbol
          : cookies.currency_selected,
      ),
    [cookies, fieldValue, value],
  );

  async function handleCurrencyChange(currencySymbol) {
    setSymbol(currencySymbol);
    await commit(value, currencySymbol);
    setCookie("currency_selected", currencySymbol);
  }

  async function handleLedgerChange(currencySymbol, valueList) {
    setSymbol(currencySymbol);
    await commit(valueList, currencySymbol);
    if (currencySymbol) {
      setCookie("currency_selected", currencySymbol);
    }
  }

  const annotation =
    !!annotations && Object.keys(annotations).length > 0
      ? annotations.filter((anno) => anno.customData?.fieldId == field.id)
      : [];

  return (
    <FormField
      label={field.name}
      field={field}
      value={value}
      nonDebouncedValueList={fieldValue.valueList}
      setValue={setValue}
      currencySymbol={symbol}
      handleCurrencyChange={handleCurrencyChange}
      required={required}
      disabled={disabled}
      handleChange={commit}
      debouncedCommit={debouncedCommit}
      flushDebouncedCommit={flushDebouncedCommit}
      userEmail={userEmail}
      handleLedgerChange={handleLedgerChange}
      contractUuid={contractUuid}
      dateFormat={dateFormat}
      onCommit={onCommit}
      isInFlight={isRawAddInFlight || isRawUpdateInFlight}
      annotations={annotations}
      annotation={annotation}
      onJumpClick={onJumpClick}
    />
  );
}

export const CONTRACT_FORMS_TERMINATION_DATE_FRAGMENT = graphql`
  fragment ContractForms_TerminationDateFragment on ContractDateType {
    id
    uuid
    label
    date
    autoRenew
    renewEvery
    renewDate
    enableReminder
    protected
    reminder {
      ...EditDateDialog_reminderFragment @relay(mask: false)
    }
  }
`;

export const CONTRACT_FORMS_DEADLINE_NONRENEW_DATE_FRAGMENT = graphql`
  fragment ContractForms_DeadlineToNonrenewFragment on ContractDateType {
    id
    uuid
    label
    date
    autoRenew
    enableReminder
    protected
    reminder {
      ...EditDateDialog_reminderFragment
    }
  }
`;

export const CONTRACT_FORMS_EFFECTIVE_DATE_FRAGMENT = graphql`
  fragment ContractForms_EffectiveDateFragment on ContractDateType {
    id
    uuid
    label
    date
    protected
  }
`;

export const STANDARD_DATES_CONTRACT_FRAGMENT = graphql`
  fragment ContractForms_StandardDates_ContractFragment on ContractType {
    effectiveDate {
      ...ContractForms_EffectiveDateFragment
    }
    terminationDate {
      ...ContractForms_TerminationDateFragment
    }
    deadlineToNonrenew {
      ...ContractForms_DeadlineToNonrenewFragment
    }
    ...TerminationDateAndReminder_ContractFragment
  }
`;

export const STANDARD_DATES_ORGANIZATION_FRAGMENT = graphql`
  fragment ContractForms_StandardDates_OrganizationFragment on OrganizationType {
    tzName
    ...TerminationDateAndReminder_OrganizationFragment
  }
`;

function DeadlineToNonrenewDate({
  contract,
  dateFormat,
  userEmail,
  required,
  disabled,
  onCommit,
  annotations,
  onContractChange,
  onJumpClick,
  ...props
}) {
  const contractFragment = useFragment(
    STANDARD_DATES_CONTRACT_FRAGMENT,
    contract,
  );
  const deadlineToNonrenew = useFragment(
    CONTRACT_FORMS_DEADLINE_NONRENEW_DATE_FRAGMENT,
    contractFragment.deadlineToNonrenew,
  );
  // need the termdate too
  const terminationDate = useFragment(
    CONTRACT_FORMS_TERMINATION_DATE_FRAGMENT,
    contractFragment.terminationDate,
  );

  const [commitSetDeadLineDate, isDeadLineDateDateInFlight] = useAsyncMutation(
    graphql`
      mutation ContractForms_SetDeadLineDateMutation(
        $contractUuid: String!
        $date: ContractDateInput!
      ) {
        updateContractDate(
          input: { contractUuid: $contractUuid, date: $date }
        ) {
          date {
            ...ContractForms_DeadlineToNonrenewFragment
          }
          contract {
            id
            currentFormMissingRequiredFields
          }
        }
      }
    `,
  );
  const enabledUI = terminationDate?.autoRenew || deadlineToNonrenew.date;
  const setDeadLineDate = useCallback(
    async (date) => {
      const updatedDNR = { ...deadlineToNonrenew, reminder: null };
      await commitSetDeadLineDate({
        variables: {
          contractUuid: contract.uuid,
          date,
        },
        optimisticResponse: {
          updateContractDate: {
            date: { ...updatedDNR, ...date },
            contract: {
              id: contract.id,
              currentFormMissingRequiredFields:
                contract.currentFormMissingRequiredFields,
            },
          },
        },
      });
      onCommit();
    },
    [
      contract.uuid,
      contract.id,
      contract.currentFormMissingRequiredFields,
      deadlineToNonrenew,
      terminationDate,
    ],
  );
  return (
    <FieldBox>
      <ErrorHandler>
        <div style={enabledUI ? {} : { pointerEvents: "none", opacity: "0.4" }}>
          <DateAndReminder
            contractUuid={contract.uuid}
            format={dateFormat}
            userEmail={userEmail}
            dateType="contract"
            date={deadlineToNonrenew}
            setDate={setDeadLineDate}
            isInFlight={isDeadLineDateDateInFlight}
            onCommit={onCommit}
            required={required}
            error={required && !deadlineToNonrenew?.date}
            disabled={disabled}
            annotations={annotations}
            onJumpClick={onJumpClick}
            name="deadline_to_nonrenew"
            dnrAutoRenew={terminationDate.autoRenew}
          />
        </div>
      </ErrorHandler>
      {!enabledUI ? (
        <Tooltip
          title="To utilize the Deadline to Nonrenew, the Termination Date must be set to autorenew"
          style={{ float: "right", marginTop: "-63px" }}
        >
          <IconButton size="tiny" color="warning" sx={{ padding: 0 }}>
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}
    </FieldBox>
  );
}

function StandardDates({
  dateFormat,
  userEmail,
  onCommit,
  required,
  disabled,
  annotations,
  onContractChange,
  onJumpClick,
  contract,
  ...props
}) {
  const contractFragment = useFragment(
    STANDARD_DATES_CONTRACT_FRAGMENT,
    contract,
  );
  const effectiveDate = useFragment(
    CONTRACT_FORMS_EFFECTIVE_DATE_FRAGMENT,
    contractFragment.effectiveDate,
  );

  const organization = useFragment(
    STANDARD_DATES_ORGANIZATION_FRAGMENT,
    props.organization,
  );

  const [commitSetEffectiveDate, isEffectiveDateInFlight] = useAsyncMutation(
    graphql`
      mutation ContractForms_SetEffectiveDateMutation(
        $contractUuid: String!
        $date: ContractDateInput!
      ) {
        updateContractDate(
          input: { contractUuid: $contractUuid, date: $date }
        ) {
          date {
            ...ContractForms_EffectiveDateFragment
          }
          contract {
            id
            currentFormMissingRequiredFields
          }
        }
      }
    `,
  );

  const setEffectiveDate = useCallback(
    async (date) => {
      await commitSetEffectiveDate({
        variables: {
          contractUuid: contract.uuid,
          date,
        },
        optimisticResponse: {
          updateContractDate: {
            date: {
              ...effectiveDate,
              ...date,
            },
            contract: {
              id: contract.id,
              currentFormMissingRequiredFields:
                contract.currentFormMissingRequiredFields,
            },
          },
        },
      });
      track("Contract Effective Date Updated");
      onCommit();
    },
    [
      contract.uuid,
      contract.id,
      contract.currentFormMissingRequiredFields,
      effectiveDate,
    ],
  );

  function setIsContractActive(isContractActive) {
    onContractChange({ isactive: isContractActive });
  }

  return (
    <GroupBox>
      <FieldBox>
        <DateAndReminder
          contractUuid={contract.uuid}
          format={dateFormat}
          userEmail={userEmail}
          dateType="standard"
          date={effectiveDate}
          setDate={setEffectiveDate}
          isInFlight={isEffectiveDateInFlight}
          onCommit={onCommit}
          required={required}
          error={required && !effectiveDate?.date}
          disabled={disabled}
          noReminders
          annotations={annotations}
          onJumpClick={onJumpClick}
        />
      </FieldBox>
      <FieldBox>
        <TerminationDateAndReminder
          contract={contractFragment}
          dateFormat={dateFormat}
          userEmail={userEmail}
          required={required}
          disabled={disabled}
          annotations={annotations}
          organization={organization}
          onJumpClick={onJumpClick}
          onCommit={onCommit}
          setIsContractActive={setIsContractActive}
        />
      </FieldBox>
    </GroupBox>
  );
}

export const CONTRACT_DATES_FRAGMENT = graphql`
  fragment ContractForms_ContractDatesFragment on ContractType {
    dates {
      ...EditDateDialog_dateFragment @relay(mask: false)
    }
  }
`;

function ContractDates({
  contract,
  dateFormat,
  userEmail,
  onCommit,
  disabled,
  annotations,
  onJumpClick,
}) {
  const [addDateOpen, setAddDateOpen] = React.useState(null);
  const { dates } = useFragment(CONTRACT_DATES_FRAGMENT, contract);

  const [commitSetDate, isInFlight] = useAsyncMutation(graphql`
    mutation ContractForms_SetContractDateMutation(
      $contractUuid: String!
      $date: ContractDateInput!
    ) {
      updateContractDate(input: { contractUuid: $contractUuid, date: $date }) {
        date {
          ...EditDateDialog_dateFragment
        }
      }
    }
  `);

  const setDate = useCallback(
    async (date) => {
      const dateObj = dates.find((x) => x.uuid == date.uuid);
      await commitSetDate({
        variables: {
          contractUuid: contract.uuid,
          date,
        },
        optimisticResponse: {
          updateContractDate: {
            date: {
              ...dateObj,
              reminder: stripFragmentProps(dateObj.reminder),
              date: date.date,
            },
          },
        },
      });
      onCommit();
    },
    [contract.uuid, onCommit],
  );

  return (
    <>
      <GroupBox>
        {List(dates)
          .sortBy((x) => parseNullableDate(x.date))
          .map((date) => (
            <FieldBox key={date.uuid}>
              <ErrorHandler>
                <DateAndReminder
                  contractUuid={contract.uuid}
                  format={dateFormat}
                  userEmail={userEmail}
                  dateType="contract"
                  date={date}
                  setDate={setDate}
                  isInFlight={isInFlight}
                  onCommit={onCommit}
                  disabled={disabled}
                  annotations={annotations}
                  onJumpClick={onJumpClick}
                />
              </ErrorHandler>
            </FieldBox>
          ))}
      </GroupBox>
      <Grid container spacing={8} alignItems="center">
        <Grid item xs={12}>
          <Button
            variant="text"
            size="tiny"
            startIcon={<Add />}
            label="Add Date"
            id="add-date-button"
            onClick={() => setAddDateOpen(true)}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      {addDateOpen ? (
        <EditDateDialog
          openFor={{}}
          setOpenFor={setAddDateOpen}
          onCommit={onCommit}
          contractUuid={contract.uuid}
          userEmail={userEmail}
          dateFormat={dateFormat}
          dateType="contract"
        />
      ) : null}
    </>
  );
}

export function SectionHeaderGrid({ children }) {
  return (
    <Box width={"100%"} mb={boxMargin}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{
          justifyContent: "space-between",
          paddingRight: "20px",
        }}
      >
        {children}
      </Grid>
    </Box>
  );
}

export const CONTRACT_FORMS_MAIN_QUERY = graphql`
  query ContractForms_Query {
    account {
      organization {
        defaultForm {
          id
          ...FormSelect_formFragment @relay(mask: false)
        }
        forms {
          ...FormSelect_formFragment
        }
        tzName
        dateFormat
        ...ContractForms_StandardDates_OrganizationFragment
      }
      ...FormSelect_accountFragment
    }
    defaultFormSections {
      ...FormSelect_formSectionFragment @relay(mask: false)
    }
  }
`;

export function groupFields(fields) {
  return fields
    .reduce((groups, field) => {
      if (field?.id.startsWith("fieldset:")) {
        return [field, ...groups];
      }
      const [curGroup, ...rest] = groups;
      if (!curGroup || curGroup.id?.startsWith("fieldset:")) {
        return [[field], ...groups];
      }
      return [[...curGroup, field], ...rest];
    }, [])
    .reverse();
}

export function getFieldList(
  sections,
  mycompanies,
  counterparties,
  effectiveDate,
  terminationDate,
  dates,
) {
  // primarily written and builds the list for pspdfkit, but could have other uses
  let linkableFields = [];

  sections.forEach((section) => {
    section.fields.forEach((fieldOrGroup) => {
      if (
        [
          "fieldset:myCompanies",
          "fieldset:counterparties",
          "fieldset:parties",
        ].includes(fieldOrGroup.id)
      ) {
        if (
          ["fieldset:myCompanies", "fieldset:parties"].includes(fieldOrGroup.id)
        ) {
          mycompanies.forEach((mycompany) => {
            linkableFields.push({
              label: mycompany.label,
              type: "party",
              id: mycompany.uuid,
            });
          });
        }

        if (
          ["fieldset:counterparties", "fieldset:parties"].includes(
            fieldOrGroup.id,
          )
        ) {
          counterparties.length > 0
            ? counterparties.forEach((cparty) => {
                linkableFields.push({
                  label: cparty.label,
                  type: "party",
                  id: cparty.uuid,
                });
              })
            : linkableFields.push({
                label: "Counterparty",
                type: "party",
                id: "newparty",
              });
        }
      } else if (fieldOrGroup.id == "fieldset:standardDates") {
        linkableFields.push(
          {
            label: effectiveDate.label,
            type: "date",
            id: effectiveDate.uuid,
          },
          {
            label: terminationDate.label,
            type: "date",
            id: terminationDate.uuid,
          },
        );
      } else if (fieldOrGroup.id == "fieldset:contractDates") {
        List(dates).forEach((date) => {
          linkableFields.push({
            label: date.label,
            type: "date",
            id: date.uuid,
          });
        });
      } else {
        linkableFields.push({
          label: fieldOrGroup.name,
          type: fieldOrGroup.fieldType,
          id: fieldOrGroup.id,
          defaultValue: fieldOrGroup.defaultValue,
          choices: fieldOrGroup.choices,
        });
      }
    });
  });

  if (terminationDate.date) {
    linkableFields.push({
      label: "Renewal Term",
      type: "rt",
      id: terminationDate.uuid,
      choices: AUTORENEW_MAP,
    });
  }

  linkableFields = linkableFields.sort((a, b) =>
    a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
  );
  return linkableFields;
}

function LoadedContractForms({
  contractUuid,
  onCommit,
  handleChange,
  userEmail,
  disabled,
  mainQueryRef,
  contractQueryRef,
  getViewerInstance,
  viewMode,
  ...props
}) {
  const { account, defaultFormSections } = usePreloadedQuery(
    CONTRACT_FORMS_MAIN_QUERY,
    mainQueryRef,
  );
  const {
    organization: { defaultForm, forms, dateFormat, ...organizationParts },
  } = account;

  const { contract } = usePreloadedQuery(
    CONTRACT_FORMS_CONTRACT_QUERY,
    contractQueryRef,
  );

  const annotations = JSON.parse(contract.annotationsJson);

  const fieldValueByFieldId = Object.fromEntries(
    [...contract.builtinFieldValues, ...contract.customFieldValues].map((x) => [
      x.field?.id,
      x,
    ]),
  );

  const [commitCurrencySymbol] = useAsyncMutation(graphql`
    mutation ContractForms_changeCurrencyMutation(
      $uuid: String!
      $currencySymbol: String!
    ) {
      updateContract(input: { uuid: $uuid, currencySymbol: $currencySymbol }) {
        contract {
          id
          currencySymbol
        }
      }
    }
  `);

  const formsById = Object.fromEntries(
    useFragment(FORM_FRAGMENT, forms).map((x) => [x.id, x]),
  );

  const currentFormId = contract?.form?.id ?? null;

  const form = formsById[currentFormId] || defaultForm;

  const [commitForm] = useAsyncMutation(FORM_MUTATION);

  async function handleChangeForm(newFormId, skipOnCommit = false) {
    const maybeOnCommit = skipOnCommit ? () => null : onCommit;
    if (disabled) {
      return;
    }

    try {
      await commitForm({
        variables: {
          contractUuid: contract.uuid,
          form: newFormId === defaultForm?.id ? null : newFormId,
        },
        optimisticUpdater: (store) => {
          const formRecord = store.get(newFormId);

          if (formRecord) {
            const contractRecord = store.get(contract.id);

            if (contractRecord) {
              contractRecord.setLinkedRecord(formRecord, "form");
            }
          }
        },
      });
      maybeOnCommit();
    } catch (e) {
      maybeOnCommit(e.message);
    }
  }

  unstable_usePrompt({
    when: contract.currentFormMissingRequiredFields,
    message:
      "Some required fields on this form are not filled out." +
      " Missing required fields are in red on the page." +
      " Are you sure you want to leave this page?",
  });

  const sections = form?.sections || defaultFormSections;
  const requiredFields = Set(form?.requiredFields?.map((x) => x.id));

  function onCommitForm(...args) {
    onCommit(...args);

    // The form may have been changed, which is not specific to this contract but could affect it.
    // Set the form to its current value, just to get an updated value for
    // currentFormMissingRequiredFields in the response.
    handleChangeForm(currentFormId);
  }

  function handleJumpClick(annotation) {
    const instance = getViewerInstance();
    instance.jumpToRect(
      annotation.pageIndex,
      new PSPDFKit.Geometry.Rect({
        left: annotation.bbox ? annotation.bbox[0] : annotation.boundingBox[0],
        top: annotation.bbox ? annotation.bbox[1] : annotation.boundingBox[1],
        width: annotation.bbox ? annotation.bbox[2] : annotation.boundingBox[2],
        height: annotation.bbox
          ? annotation.bbox[3]
          : annotation.boundingBox[3],
      }),
    );

    setTimeout(() => {
      instance.setSelectedAnnotations(annotation.id);
    }, 250);
  }

  return (
    <Paper>
      {!sections.length ? (
        <SectionHeaderGrid>
          <Grid item />
          <Grid item>
            <ContractFormSelect
              account={account}
              contract={contract}
              value={currentFormId}
              onChange={handleChangeForm}
              onCommit={onCommitForm}
            />
          </Grid>
        </SectionHeaderGrid>
      ) : (
        sections.map((section, i) => (
          <React.Fragment key={i}>
            {i > 0 ? (
              <Box width={"100%"} my={boxMargin * 2}>
                <Divider />
              </Box>
            ) : null}
            <SectionHeaderGrid>
              <Grid item>
                <Typography variant="h5">{section.name}</Typography>
              </Grid>
              {i == 0 ? (
                <Grid item>
                  <ContractFormSelect
                    account={account}
                    contract={contract}
                    value={currentFormId}
                    onChange={handleChangeForm}
                    onCommit={onCommitForm}
                  />
                </Grid>
              ) : null}
            </SectionHeaderGrid>
            {groupFields(section.fields)?.map((group, i) =>
              group.id == "fieldset:myCompanies" ? (
                <MyCompaniesFieldset
                  key={group.id}
                  contract={contract}
                  onCommit={onCommit}
                  required={requiredFields.has(group.id)}
                  disabled={disabled}
                  annotations={viewMode == "sideBySide" ? annotations : null}
                  onJumpClick={handleJumpClick}
                />
              ) : group.id == "fieldset:counterparties" ? (
                <CounterpartiesFieldset
                  key={group.id}
                  contract={contract}
                  onCommit={onCommit}
                  required={requiredFields.has(group.id)}
                  disabled={disabled}
                  annotations={viewMode == "sideBySide" ? annotations : null}
                  onJumpClick={handleJumpClick}
                />
              ) : group.id == "fieldset:parties" ? (
                <PartiesFieldset
                  key={group.id}
                  contract={contract}
                  onCommit={onCommit}
                  required={requiredFields.has(group.id)}
                  disabled={disabled}
                  annotations={viewMode == "sideBySide" ? annotations : null}
                  onJumpClick={handleJumpClick}
                />
              ) : group.id == "fieldset:standardDates" ? (
                <StandardDates
                  key={group.id}
                  contract={contract}
                  dateFormat={dateFormat}
                  userEmail={userEmail}
                  onCommit={onCommit}
                  required={requiredFields.has(group.id)}
                  disabled={disabled}
                  organization={organizationParts}
                  annotations={viewMode == "sideBySide" ? annotations : null}
                  onContractChange={handleChange}
                  onJumpClick={handleJumpClick}
                />
              ) : group.id == "fieldset:contractDates" ? (
                <ContractDates
                  key={group.id}
                  contract={contract}
                  dateFormat={dateFormat}
                  userEmail={userEmail}
                  onCommit={onCommit}
                  disabled={disabled}
                  annotations={viewMode == "sideBySide" ? annotations : null}
                  onJumpClick={handleJumpClick}
                />
              ) : (
                <GroupBox key={i}>
                  {group
                    .filter((f) => {
                      return f?.id;
                    })
                    .map((field) =>
                      field.id == "builtin:deadline_to_nonrenew" ? (
                        <DeadlineToNonrenewDate
                          key={field.id}
                          contract={contract}
                          dateFormat={dateFormat}
                          userEmail={userEmail}
                          onCommit={onCommit}
                          disabled={disabled}
                          required={requiredFields.has(group.id)}
                          annotations={
                            viewMode == "sideBySide" ? annotations : null
                          }
                          onJumpClick={handleJumpClick}
                        />
                      ) : (
                        <FieldBox key={field.id} width={null}>
                          <ErrorHandler>
                            <Field
                              contractId={contract.id}
                              contractUuid={contract.uuid}
                              field={field}
                              fieldValue={
                                fieldValueByFieldId[field.id] || {
                                  value: field.defaultValue,
                                }
                              }
                              dateFormat={dateFormat}
                              onCommit={onCommit}
                              userEmail={userEmail}
                              required={requiredFields.has(field.id)}
                              disabled={disabled}
                              annotations={
                                viewMode == "sideBySide" ? annotations : null
                              }
                              onJumpClick={handleJumpClick}
                            />
                          </ErrorHandler>
                        </FieldBox>
                      ),
                    )}
                </GroupBox>
              ),
            )}
          </React.Fragment>
        ))
      )}
    </Paper>
  );
}

export default function ContractForms(props) {
  return (
    <Suspense fallback={<PaperProgress />}>
      <LoadedContractForms key={props.contractUuid} {...props} />
    </Suspense>
  );
}
